<template>
    <div class="field">
        <label
            v-if="label"
            class="label is-small"
        >
            {{ label }}
        </label>
        <div
            class="tags-input-container input is-small"
            :class="{ 'container-focus': focused, '': !focused }"
            @click="$refs.tagInput.focus()"
        >
            <span
                v-for="tag in modelValue"
                :key="tag.pk"
                class="tag"
            >
                {{ tag.label }}
                <i
                    class="fa-solid fa-xmark"
                    @click.stop="removeTag(tag)"
                ></i>
            </span>
            <div>
                <input
                    ref="tagInput"
                    v-model="text"
                    placeholder="Add tag..."
                    :class="{
                        'input-focused': focused,
                        'input-blur': !focused,
                    }"
                    @keydown.enter="addTagFromAutocomplete(results[0])"
                    @focus="focused = true"
                    @blur="focused = false"
                />
            </div>
            <div
                v-if="text.length && results.length"
                class="autocomplete-list"
            >
                <div
                    v-for="tag in results"
                    :key="tag.pk"
                    class="autocomplete-item"
                    @click.stop="addTagFromAutocomplete(tag)"
                >
                    {{ tag.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
    name: 'TagsField',
    props: {
        pkField: {
            type: String,
            default: 'pk',
        },
        modelValue: {
            type: Array,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        endpoint: {
            type: String,
            required: true,
        },
        minchars: {
            type: Number,
            default: 1,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['update:modelValue'],
    data: () => ({
        text: '',
        selectedIndex: undefined,
        results: [],
        focused: false,
    }),
    watch: {
        text: debounce(async function (newval) {
            if ((newval?.length || 0) >= this.minchars) {
                if (this.pkField === 'pk') {
                    this.results = await this.$request(
                        this.endpoint,
                        {
                            criteria: newval,
                            filters: this.filters,
                            max_ocurrences: this.maxResults,
                        },
                        undefined,
                        true
                    )
                } else if (this.pkField === 'id') {
                    const results = await this.$autocomplete.search(
                        this.endpoint,
                        newval,
                        this.filters
                    )
                    for (const item of results) {
                        item.label = item.name
                    }
                    this.results = results
                }
            }
        }, 400),
    },
    methods: {
        addTagFromAutocomplete(tag) {
            let exists = false
            if (this.modelValue) {
                exists = this.modelValue.find(
                    (x) => x[this.pkField] === tag[this.pkField]
                )
            }

            if (!exists) {
                const value = this.modelValue || []
                value.push(tag)
                this.$emit('update:modelValue', value)
                this.text = ''
            }
        },
        removeTag(tag) {
            this.$emit(
                'update:modelValue',
                this.modelValue.filter(
                    (x) => x[this.pkField] !== tag[this.pkField]
                )
            )
        },
    },
}
</script>

<style scoped lang="scss">
.tags-input-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    height: 100%;
    min-height: 30px;
    gap: 0.2rem;
}

.container-focus {
    border: 1px solid #ea621f;
    outline: 1px solid #facdca;

    &:hover {
        border: 1px solid #ea621f !important;
        outline: 1px solid #facdca !important;
    }
}

.tag {
    background-color: #ea621f;
    color: #fff;
    margin-bottom: 0 !important;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.fa-xmark {
    color: #fff;
    cursor: pointer;
    margin-left: 0.5em;
    height: 10px;
}

input {
    border: none;
    max-height: 16px;
    font-size: 12px;
    text-indent: 5px;
}

input:focus {
    outline: none;
}

.input-focused {
    height: 100%;
    width: 100px;
}

.input-blur {
    height: 0;
    width: 0;
}

.autocomplete-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 10000;
}

.autocomplete-item {
    padding: 0.5em;
    cursor: pointer;
    background-color: #f9f9f9;
}

.autocomplete-item:hover {
    background-color: #e0e0e0;
}
</style>
