<template>
    <router-link
        :class="classes"
        :to="linkRoute"
        :target="target"
    >
        <slot>
            {{ choicesLabel('LeadStatus', status) }}
        </slot>
    </router-link>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            default: undefined,
        },
        tab: {
            type: String,
            default: undefined,
        },
        newPage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return `tag status-${this.status}`
        },
        linkRoute() {
            if (this.link) {
                const result = {
                    name: 'LeadUpdate',
                    params: {
                        lead: this.link,
                    },
                }

                if (this.tab) {
                    result.query = {
                        tab: this.tab,
                    }
                }

                return result
            }
            return ''
        },
        target() {
            if (this.newPage) {
                return '_blank'
            }

            return undefined
        },
    },
}
</script>

<style scoped>
.status-0 {
    background-color: #e03b24 !important;
    color: white !important;
}

.status-Q {
    background-color: #f77b6a !important;
    color: white !important;
}

.status-A {
    background-color: #64a338 !important;
    color: white !important;
}

.status-1 {
    background-color: #64a338 !important;
    color: white !important;
}

.status-2 {
    background-color: #ffcc00 !important;
    color: black !important;
}

.status-3 {
    background-color: #ffcc00 !important;
    color: black !important;
}

.status-4 {
    background-color: #64a338 !important;
    color: white !important;
}

.status-5 {
    background-color: #64a338 !important;
    color: white !important;
}

.status-6 {
    background-color: #873600 !important;
    color: white !important;
}

.status-7 {
    background-color: #000000 !important;
    color: white !important;
}
</style>
