import setupChartComponents from './charts'
import setupControlComponents from './controls'
import setupContainerComponents from './containers'
import setupModalsComponents from './modals'
import setupVisualizationComponents from './visualization'
import setupV2Components from './v2'

import setupNewComponents from './new'
import setupSectionsComponents from './sections'
import setupSpecificComponents from './specific'
import setupUtilsComponents from './utils'
import setupViewsComponents from './views'

import DropdownMenu from './DropdownMenu.vue'
import LangFlag from './LangFlag.vue'
import ValidationErrors from './ValidationErrors.vue'

export default function (app) {
    setupChartComponents(app)
    setupControlComponents(app)
    setupContainerComponents(app)
    setupModalsComponents(app)
    setupVisualizationComponents(app)
    setupNewComponents(app)
    setupSectionsComponents(app)
    setupSpecificComponents(app)
    setupUtilsComponents(app)
    setupViewsComponents(app)
    setupV2Components(app)

    app.component('T3DropdownMenu', DropdownMenu)
    app.component('T3LangFlag', LangFlag)
    app.component('T3ValidationErrors', ValidationErrors)
}
