<template>
    <canvas ref="canvas"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

var charts = {}

export default {
    name: 'BarPlot',
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: undefined,
        },
        borderWidth: {
            type: Number,
            default: 1,
        },
        yLabel: {
            type: String,
            default: undefined,
        },
        ySuffix: {
            type: String,
            default: '',
        },
    },
    computed: {
        labels() {
            return this.modelValue.map((x) => x.label)
        },
        datasets() {
            return [
                {
                    label: this.label,
                    data: this.modelValue.map((x) => x.value),
                    borderWidth: this.borderWidth,
                    backgroundColor: this.modelValue.map(
                        (x) => x.backgroundColor
                    ),
                },
            ]
        },
    },
    watch: {
        datasets() {
            const uid = this.$.uid

            charts[uid].data.labels = this.labels
            charts[uid].data.datasets = this.datasets
            charts[uid].options.scales.y.title.text = this.yLabel
            charts[uid].options.scales.y.ticks = {
                callback: (value) => `${value}${this.ySuffix}`,
            }

            charts[uid].update()
        },
    },
    mounted() {
        const ctx = this.$refs.canvas

        const uid = this.$.uid

        charts[uid] = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.labels,
                datasets: this.datasets,
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: this.label !== undefined,
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: this.yLabel !== undefined,
                            text: this.yLabel,
                        },
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => `${value}${this.ySuffix}`,
                        },
                    },
                },
            },
        })
    },
}
</script>
