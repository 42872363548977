export default [
    {
        name: 'EventList',
        path: 'events',
        component: () => import('./views/EventList.vue'),
        meta: {
            title: 'Events',
            perms: 'locations',
            icon: 'fa-solid fa-calendar-days',
        },
    },
    {
        name: 'EventCreate',
        path: 'events/create',
        component: () => import('./views/EventForm.vue'),
    },
    {
        name: 'EventUpdate',
        path: 'events/:event',
        component: () => import('./views/EventForm.vue'),
    },
]
