export default [
    {
        name: 'HotelModificationRequestList',
        path: 'tasks/hotels/modifications',
        component: () => import('./views/HotelModificationRequestList.vue'),
        meta: {
            title: 'Hotel Info Modification Request',
            perms: 'hotels',
            icon: 'fa-solid fa-info-circle',
        },
    },
    {
        name: 'HotelModificationRequestUpdate',
        path: 'tasks/hotels/modifications/:modification',
        component: () => import('./views/HotelModificationRequestUpdate.vue'),
    },
    {
        name: 'HoteliersPendingFromVerification',
        path: 'tasks/hoteliers/pending/verification',
        component: () => import('./views/HoteliersPendingFromVerification.vue'),
        meta: {
            title: 'Hoteliers Pending from Verification',
            perms: 'tasks',
            icon: 'fa-solid fa-user-check',
        },
    },
    {
        name: 'SupplierInvoicesMissing',
        path: 'tasks/invoices/suppliers',
        component: () => import('./views/SupplierInvoicesMissing.vue'),
        meta: {
            title: 'Supplier Invoices Missing',
            perms: 'tasks',
            icon: 'fa-solid fa-file-invoice',
        },
    },
    // StartSection - Call List
    {
        name: 'ClientNewCallList',
        path: 'calls/clients/new',
        component: () => import('./views/ClientNewCallList.vue'),
        meta: {
            title: 'New Client Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'client_new',
        },
    },
    {
        name: 'ClientFollowCallList',
        path: 'calls/clients/follow',
        component: () => import('./views/ClientFollowCallList.vue'),
        meta: {
            title: 'Follow Client Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'client_follow',
        },
    },
    {
        name: 'ClientCatchUpCallList',
        path: 'calls/clients/catchup',
        component: () => import('./views/ClientCatchUpCallList.vue'),
        meta: {
            title: 'Catch Up Client Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'client_catch_up',
        },
    },
    {
        name: 'HotelPendingCallList',
        path: 'calls/hotels/pending',
        component: () => import('./views/HotelPendingCallList.vue'),
        meta: {
            title: 'Hotel Pending Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'hotel_pending',
        },
    },
    {
        name: 'HotelBlockCallList',
        path: 'calls/hotels/block',
        component: () => import('./views/HotelBlockCallList.vue'),
        meta: {
            title: 'Hotel Block Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'hotel_block',
        },
    },
    {
        name: 'HotelModificationCallList',
        path: 'calls/hotels/modifications',
        component: () => import('./views/HotelModificationCallList.vue'),
        meta: {
            title: 'Hotel Modification Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'hotel_modification',
        },
    },
    {
        name: 'HotelCheckInCallList',
        path: 'calls/hotels/checkins',
        component: () => import('./views/HotelCheckInCallList.vue'),
        meta: {
            title: 'Hotel Check-In Call List',
            perms: 'tasks',
            icon: 'fa-solid fa-phone',
        },
        props: {
            reason: 'hotel_check_in',
        },
    },
    // EndSection - Call List
]
